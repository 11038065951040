<template>
	<Tabs :tabs="tabs">
		<template #header>
            <VMultiselect
                track-by="route"
                :options="tabs"
                :multiple="false"
                :searchable="false"
                :value="selectedTab"
                :allow-empty="false"
                :close-on-select="true"
                @select="handleTabSelect"
            />
		</template>

		<transition name="fade" mode="out-in">
			<router-view />
		</transition>
	</Tabs>
</template>

<script>
export default {
	name: 'Landing',
	data() {
		return {
			tabs: [
				{
					route: 'engaging-with-government',
					name: 'The Policy space'
				},
				{
					route: 'policy-advocacy-index',
					name: 'Process indicators'
				},
				{
					route: 'policy-advocacy-data-explorer',
					name: 'Data explorer'
				}
            ]
		};
    },
    computed: {
        selectedTab() {
            return this.tabs.find(t => t.route.includes(this.$route.name));
        }
    },
    methods: {
        handleTabSelect(tab) {
            this.$router.push({name: tab.route});
        }
    }
};
</script>
